import { UPDATE_USER, UPDATE_NOTIFICATION } from "./userActionTypes";

// eslint-disable-next-line import/prefer-default-export
export function updateUser(profile) {
    return {
        type: UPDATE_USER,
        profile,
    };
}

export function updateNotification(notification, updateMode) {
    return {
        type: UPDATE_NOTIFICATION,
        notification,
        updateMode,
    };
}
