export default {
    name: "Vanzo Air Fragrance",
    fullName: "VANZO New Generation Sterilizing Healthy Air Fragrance",
    url: "https://hihome2.ant-internet.com",
    color: "#ddb881",
    meta_title: "VANZO New Generation Sterilizing Healthy Air Fragrance",
    meta_description: "",
    meta_keyword: "",
    author: {
        name: "Ant Internet Shd Bhd",
        profile_url: "https://ant-internet.com/",
        registration_number: "1199944-V",
    },
    contacts: {
        address: "715 Fake Street, New York 10021 USA",
        email: "stroyka@example.com",
        phone: "(800) 060-0730",
    },
};
