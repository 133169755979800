import { SHIPPINGINFO_CLOSE, SHIPPINGINFO_OPEN } from './shippingInfoActionTypes';

const initialState = {
    open: false,
    info: null,
};

export default function shippingInfoReducer(state = initialState, action) {
    let newState = state;

    if (action.type === SHIPPINGINFO_OPEN) {
        newState = {
            ...state,
            open: true,
            info: JSON.parse(JSON.stringify(action.info)),
        };
    } else if (action.type === SHIPPINGINFO_CLOSE) {
        newState = {
            ...state,
            open: false,
        };
    }

    return newState;
}
