// react
import React, { useState, useEffect, createRef } from "react";

// third-party
import { Link } from "react-router-dom";

// application
import Indicator from "./Indicator";
import { NotificationSvg } from "../../svg";
import * as USER from "../../api/user";
import { getAccessToken } from "../../api/auth";
import { updateNotification } from "../../store/user";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

export function IndicatorNotification(props) {
    const { from } = props;
    const token = getAccessToken();
    const [notification, setNotifications] = useState([]);
    const [imgRef, setImgRef] = React.useState([]);
    const dispatch = useDispatch();
    let notificationCount = useSelector((state) => state?.user?.notification);

    useEffect(() => {
        if (token && from == "header") {
            callUserNotification({ is_count: 1 });
        }
    }, [token]);

    React.useEffect(() => {
        // add or remove refs
        setImgRef((imgRef) =>
            Array(notification.length)
                .fill()
                .map((_, i) => imgRef[i] || createRef())
        );
    }, [notification.length]);

    const readNotification = () => {
        if (notificationCount > 0) callUserNotification();
        else setNotifications([]);
    };

    function callUserNotification(params) {
        let canceled = false;
        USER.getMyNotification(params ? params : {})
            .then((res) => {
                if (canceled) {
                    return;
                }
                if (res.data) {
                    if (!params) setNotifications(res.data.reverse());
                    dispatch(updateNotification(params ? res.data : 0));
                } else {
                    canceled = true;
                }
            })
            .catch((err) => {});
    }

    const notificationDropdown = (
        <div className="account-menu notification">
            <div className="notification-list">
                {notification && notification.length > 0 ? (
                    notification.map((item, index) => {
                        return (
                            <div key={item.uuid} className="notification-row">
                                <div className="content">
                                    <div className="dateTime">{moment(item.created_at).fromNow()}</div>
                                    <div className="title">{item.data?.title}</div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="no-result-msg">No New Notification</div>
                )}
            </div>

            <div className="footer-link">
                <Link to="/account/notifications">View All</Link>
            </div>
        </div>
    );

    return from == "mobile" ? (
        <Indicator url="/account/notifications" value={notificationCount} icon={<NotificationSvg />} />
    ) : (
        <Indicator
            dropdown={notificationDropdown}
            url="/account/notifications"
            openNotification={() => readNotification()}
            clearNotification={() => setNotifications([])}
            value={notificationCount ? notificationCount : 0}
            icon={<span className={notificationCount === 0 && from === "header" ? "no-item" : null}>{<NotificationSvg />}</span>}
        />
    );
}

export default IndicatorNotification;
