// react
import React from "react";
import { Link } from "react-router-dom";

export default function FooterAboutUs() {
    return (
        <div className="site-footer__widget footer-about">
            {/* <h5 className="footer-about__title">About</h5> */}
            <Link to="/">
                <img src="images/logos/logo.png" alt="logo" className="footer-logo" />
            </Link>
        </div>
    );
}
